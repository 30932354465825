<template>
  <v-card>
    <v-card-title class="headline green lighten-1">
      已选择资源清单
    </v-card-title>

    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="desserts"
          sort-by="calories"
          :class="'elevation-1 table_proxy_status_'+desserts_proxy"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>已选择清单</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">确认移除吗?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
                   <v-btn color="blue darken-1" text @click="deleteItemConfirm">确认</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
<!--          <v-icon-->
<!--              small-->
<!--              class="mr-2"-->
<!--              @click="editItem(item)"-->
<!--          >-->
<!--            mdi-pencil-->
<!--          </v-icon>-->
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          text
          @click="wannaAll"
      >
        立即打包
      </v-btn>
    </v-card-actions>
    <RsPatch ref="rsPatchDialog" @closeCart="closeCart" />
  </v-card>
</template>

<script>
import { find_all_by_ids } from "@/api/resource";
import RsPatch from "@/components/RsPatch";

export default {
  name: "RsCartMini",
  components: {RsPatch},
  data(){
    return {
      dialog: false,
      dialogDelete: false,
      rsPatchVisible:false,
      headers: [
        {
          text: '资源名称',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: '类 型', value: 'type' },
        { text: '数 量', value: 'dup' },
        { text: '操 作', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      cart_ids:[],
      desserts_proxy:undefined
    }
  },
  watch:{
    desserts_proxy:function (){
      if(this.cart_ids.sort().toString()!==this.$store.state.rs_in_cart.sort().toString()){
        this.init_cart_mini()
        return this.cart_ids.sort().toString()
      }
      return this.$store.state.rs_in_cart.sort().toString()
    }
  },
  created:function (){
    console.log('打开购物车')
    this.init_cart_mini()
  },
  methods:{
    find_all_rs(){
      let that = this
      return find_all_by_ids(this.cart_ids).then(response=>{
        this.desserts= response.map(function (v){
          v['dup']=that.$store.state.rs_in_cart.filter(it => it===v['id']).length
          return v
        })
      })
    },
    init_cart_mini:function (){
      this.cart_ids=this.$store.state.rs_in_cart
      this.find_all_rs()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    _removeFromcart(){
      if(this.desserts[this.editedIndex]['dup']>1){
        this.desserts[this.editedIndex]['dup']=this.desserts[this.editedIndex]['dup']-1
      }else{
        this.desserts.splice(this.editedIndex, 1)
      }
    },
    deleteItemConfirm () {
      this.$store.commit('removeFromCart',this.desserts[this.editedIndex]['id'])
      this._removeFromcart()
    console.log(this.desserts[this.editedIndex])//id:3
      this.closeDelete()
    },
    editItem(item) {
      //编辑某个
      this.editedIndex = this.desserts.indexOf(item)
      //
    },
    wannaAll() {
      // this.dialog = false
      console.log('调用购物车组件的 打包全部方法')
      // this.rsPatchVisible=true
      this.$refs.rsPatchDialog.dialog=!this.$refs.rsPatchDialog.dialog
      // this.$emit('switch_cart_visible',1)

    },
    closeCart(){
      console.log("购物车即将关闭")
      this.$emit('switch_cart_visible')
    }
  }
}
</script>

<style scoped>

</style>
