<template id="app">
  <v-app>
    <v-app-bar
        app
        color="white"
        flat
    >
      <v-container class="py-0 fill-height">
        <v-avatar
            class="mr-10"
            color="grey darken-1"
            size="42"
        >
          <img
              src="../../public/default.jpg"
              alt="RurjsRslib"
          >
        </v-avatar>
        <router-link
            v-for="link in links"
            :key="link.name"
            style="text-decoration: none"
            target="_self" :to="link.url">
          <v-btn
              text
          >
            {{ link.name }}
          </v-btn>
        </router-link>
        <v-btn text @click="switch_cart_visible">
          RSCART
          <v-badge
              v-if="cart_list.length>0"
              color="green"
              :content="cart_list.length"
          >
          <v-icon
              dark
              right
          >
            mdi-cart-variant
          </v-icon>
          </v-badge>
          <v-icon
              v-else
              dark
              right
          >
            mdi-cart-variant
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-responsive max-width="260">
          <v-text-field
              v-model="searchKey"
              dense
              flat
              hide-details
              rounded
              solo-inverted
              placeholder="输入关键字以搜索资源"
              append-icon="mdi-magnify"
              @click:append="query"
              @keydown="queryEnter"
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container>
          <slot></slot>
      </v-container>
    </v-main>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <RsCartMini v-if="dialog"  @switch_cart_visible="switch_cart_visible"  :visible.sync="dialog" />
    </v-dialog>
  </v-app>
</template>

<script>

import RsCartMini from "@/components/RsCartMini";
export default {
  components: {RsCartMini},
  data: () => ({
    links: [
      {name:'Homepage',url:'/'},
      {name:'Messages',url:'/'},
      {name:'Profile',url:'/'},
      {name:'Updates',url:'/'},
    ],
    searchKey: undefined,
    dialog: false
  }),
  computed:{
    cart_list:function (){
      return this.$store.state.rs_in_cart
    }
  },
  created: function () {
    // this.query
  },
  methods:{
    query:function (){
      console.log("------------------")
      this.$emit('func',{
        type: undefined,
        name: this.searchKey
      })
    },
    queryEnter: function (e) {
      if(e.code==='Enter'){
        this.query()
      }
    },
    switch_cart_visible() {
      console.log('调用了父组件的切换购物车状态方法')
      this.dialog= !this.dialog
    }
  }

}
</script>
