<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
          class="mx-auto text-left"
          max-width="344"
      >
          <v-img :src="pic" class="" max-height="220px" max-width="220px" contain></v-img>

        <v-card-text class="" style="padding-bottom: 6px;height:99px">
          <h6 class="title warning--text">
            {{ title }}
          </h6>
          <v-divider>inset</v-divider>
          <span class="rs-line-limit-1 secondary--text">{{ author_join }}</span>
          <span class="rs-line-limit-1">{{ press.name }}</span>
        </v-card-text>

        <v-card-title max-height="16px" class="" style="padding-top:0;bottom: 0">
          <v-container class="grey lighten-5 pa-0">
            <v-row no-gutters>
              <v-col><span class="secondary--text subtitle-2 float-left">浏览次数：{{Math.floor(Math.random()*100)}}</span></v-col>
              <v-col><span class="success--text subtitle-2 float-right">{{ type }}</span></v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-fade-transition>
          <v-overlay
              v-if="hover"
              absolute
              color="#036358"
          ><v-container>
            <v-row no-gutters>
              <v-col cols="12" class="pa-0" style="height: 200px">
                <v-card-text>
                    <span class="primarry--text rs-description" >{{ description }}</span>
                </v-card-text>
              </v-col>
              <v-col cols="12" class="pa-3">
                <v-btn color="success" @click="add2Cart">添加到<v-icon
                    dark
                    right
                >
                  mdi-cart-variant
                </v-icon>
                </v-btn>
                <v-tooltip bottom open-delay="600">
                  <template v-slot:activator="{ on, attrs}">
                    <router-link  target="_blank" :to="'/detail/'+identify">
                      <v-btn  v-bind="attrs"
                              v-on="on"
                              color="primary"
                      >
                        <v-icon>mdi-watch-export-variant</v-icon>
                    </v-btn>
                    </router-link>
                  </template>
                  <span>{{ text.show_detail }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: "ResourceCard",
  props: {
    title: {
      type: String,
      default: 'hello world'
    },
    pic: {
      type: String,
      default: 'http://localhost/1.jpg'
    },
    description: {
      type: String,
      default: '暂无更多信息'
    },
    type: {
      type: String,
      default: '其他'
    },
    press: {
      type: Object,
      default: ()=>{}
    },
    authors: {
      type: Array,
      default: ()=>[]
    },
    identify: {
      type: Number,
      default: undefined
    },
  },
  data: () => ({
    overlay: false,
    icons:['mdi-music','book-open-page-variant','video','image'],
    text: {
      show_detail:'点击查看详细信息'
    }
  }),
  computed: {
    author_join: function () {
      let res =''
      if(this.authors.length<1)return '无'
      this.authors.forEach(function (v){
        res=res+','+v['name']
      })
      return res.substring(1,res.length)
    }
  },
  methods:{
  add2Cart:function (){
    this.$store.commit('push2cart',this.identify)
  }
  }
}
</script>

<style scoped>
.rs-line-limit-1{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rs-description{
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
