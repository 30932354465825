import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// create an axios instance
import router from "@/router";
let baseUrl=undefined
if(process.env.VUE_APP_BASE_API!==undefined){
    baseUrl=process.env.VUE_APP_BASE_SERVER + process.env.VUE_APP_BASE_API
}else{
    baseUrl="http://localhost:9999"
}
const service = axios.create({
    baseURL: baseUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        // if (store.getters.token) {
        //     // let each request carry token
        //     // ['X-Token'] is a custom headers key
        //     // please modify it according to the actual situation
        //     config.headers['Authorization'] = 'Bearer ' + getToken()
        // }
        return config
    },
    error => {
        // do something with request error
        // console.log('request error code unhnown')
        // console.log('err2·：' + error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        if (!res.success) {
            // Message({
            //     message: res.msg || 'Error',
            //     type: 'error',
            //     duration: 5 * 1000
            // })

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // if (res.code >= 40001 && res.code <= 40006) {
            //     // to re-login
            //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
            //         confirmButtonText: 'Re-Login',
            //         cancelButtonText: 'Cancel',
            //         type: 'warning'
            //     }).then(() => {
            //         store.dispatch('user/resetToken').then(() => {
            //             location.reload()
            //         })
            //     })
            // }
            if (res.code===40000) {
                window.console.log('404,跳转404')
                router.push('/404')
            }
            return Promise.reject(new Error(res.msg || 'Error, but not message'))
        } else {
            return res.data
        }
    },
    error => {
        // console.log('err·1: ') // for debug
        // console.log(error.response)
        // console.log()
        // console.log('-------------xxxxx-----------')
        // console.log(error，FormData对象 在 edge浏览器 not clone able)
        // Todo 兼容性问题,edge浏览器不可克隆 FormData对象
        // Message({
        //     message: error.response.config.url.indexOf('oauth/token') > 0 ? '用户名或密码错误' : error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
)

export default service
