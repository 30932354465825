<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          发送订单到邮箱
        </v-card-title>

        <v-card-text>
          <v-text-field
        v-model="mail"
        :label="`邮箱 — ${isEditing ? '输入' : '完成'}`"
        :readonly="!isEditing"
        persistent-hint
        prepend-icon="mdi-city"
      >
          <template v-slot:append-outer>
            <v-slide-x-reverse-transition
              mode="out-in"
            >
              <v-icon
                :key="`icon-${isEditing}`"
                :color="isEditing ? 'success' : 'info'"
                @click="isEditing = !isEditing"
                v-text="isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
              ></v-icon>
            </v-slide-x-reverse-transition>
          </template>
          </v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="sendTax2Me"
          >
            确认发送
            <v-icon
        right
        dark
      >
        mdi-send
      </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-overlay :value="overlay" style="z-index=9999">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { package2Me } from "@/api/resource";

export default {
  name: "RsPatch",
  data () {
    return {
      dialog: false,
      isEditing: true,
      mail: null,
      states: [],
      overlay:false
    }
  },
  methods:{
    sendTax2Me() {
      this.overlay=true
      this.isEditing=false
      let that=this
      package2Me({email:this.mail,resouceids:this.$store.state.rs_in_cart}).then(response=>{
          console.log('打包结果：')
          console.log(response)
          if(response==true){
            that.$emit('closeCart')
            that.$store.commit('clearCart')
            }
          this.overlay=false
          this.dialog=false
          
      })
      this.dialog=false
    }
  }
}
</script>

<style scoped>

</style>
