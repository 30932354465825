import request from "@/request/request";

const module_url='/api/rshe/resource'

export function query_list(queryParam){
    return request({
        method:'get',
        url: module_url,
        params: queryParam
    })
}

export function find_one(id){
    return request({
        method:'get',
        url: module_url+'/' + id
    })
}

export function find_all_by_ids(ids){
    return request({
        method:'post',
        url: module_url+'/list',
        data:{ids:ids}
    })
}

export function package2Me(data){
    return request({
        method:'post',
        url: module_url+'/package',
        data:data,
        timeout: 1 * 60 * 1000
    })
}
