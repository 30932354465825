import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/detail/:id',
    name: 'ResourceDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/pages/ResourceDetail.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/pages/404')
  },
  {
    path: '/test',
    name: 'NotFound',
    component: () => import('@/pages/404_2')
  }
]

const router = new VueRouter({
  routes
})

export default router
