<template>
    <main-layout @func="doSearchFromMain">
        <v-row>
            <v-col cols="2">
              <v-sheet rounded="lg">
                <v-list color="transparent">
                  <v-list-item-group
                      v-model="rs_type_selected"
                      color="primary"
                      @change="changeResourceType"
                  >
                    <v-list-item
                        v-for="n in rs_types"
                        :key="n.name"
                        link
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ n.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider class="my-2"></v-divider>

                    <v-list-item
                        link
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          所有资源
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-sheet>
            </v-col>

            <v-col>
              <v-sheet
                  min-height="70vh"
                  rounded="lg"
                  style="padding:10px;"
              >
                <v-row>
                  <v-col
                      v-for="n in resources"
                      :key="n.id"
                      cols="2"
                      sm="6"
                      xl="2"
                      md="3"
                      lg="4"
                  >
                    <v-card height="346" min-width="220" style="overflow: hidden">
                      <ResourceCard
                          :title="n.name"
                          :pic="n.frontCover"
                          :description="n.description"
                          :authors="n.authors"
                          :press="n.press"
                          :type="n.type"
                          :identify="n.id"
                      />
                    </v-card>
                  </v-col>
                </v-row>
                <!--  -->
              </v-sheet>
            </v-col>
          </v-row>
    </main-layout>
</template>
<script>
import { query_list } from "@/api/resource";
import ResourceCard from "@/components/ResourceCard";
import MainLayout from '@/layouts/Main'

export default {
  components: {ResourceCard, MainLayout},
  data: () => ({
    links: [
      'Dashboard',
      'Messages',
      'Profile',
      'Updates',
    ],
    rs_types:[
      {name:'书籍类',key:'DOCUMENT'},
      {name:'音频类',key:'MUSIC'},
      {name:'图片资源',key:'IMAGE'},
      {name:'其他',key: 'OTHER'},
    ],
    rs_type_selected: 4,
    resources:[],
    totalElements:0,
    searchKey: undefined
  }),
  computed:{
    cart_list:function (){
      // return this.$store.state.rs_in_cart
      return []
    }
  },
  created: function () {
    this.query()
  },
  methods:{
    query:function (){
      console.log("------------------")
      query_list({
        type: this.rs_type_selected<this.rs_types.length?this.rs_types[this.rs_type_selected]['key']:undefined,
        name: this.searchKey
      }).then(response=>{
        this.resources=response.contents
        this.totalElements=response.totalElements
      })
    },
    changeResourceType: function (param) {
      console.log(param)
      this.query()
    },
    queryEnter: function (e) {
      if(e.code==='Enter'){
        this.query()
      }
    },
    doSearchFromMain: function (dat){
      this.searchKey=dat['name']
      this.query()
    }
  }

}
</script>
