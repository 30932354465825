import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    rs_in_cart:[]
  },
  mutations: {
    push2cart(state,id){
      state.rs_in_cart.push(id)
    },
    removeFromCart(state,id){
      console.log('移除id为：'+id+'的资源')
      console.log(state.rs_in_cart)
      const rm_index=state.rs_in_cart.indexOf(id)
      if(rm_index>-1){
        state.rs_in_cart.splice(rm_index,1)
      }
    },
    clearCart(state){
        state.rs_in_cart=[]
    }
  },
  actions: {
  },
  modules: {
  }
})
